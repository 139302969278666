export const majors = [
  "AFRICAN AMERICAN STUDIES",
  "AFRICAN STUDIES",
  "AMERICAN STUDIES",
  "ANTHROPOLOGY",
  "APPLIED MATHEMATICS",
  "ART HISTORY",
  "ART THEORY AND PRACTICE",
  "ASIAN AMERICAN STUDIES",
  "ASIAN LANGUAGES AND CULTURES",
  "BIOLOGICAL SCIENCES",
  "BIOMEDICAL ENGINEERING",
  "CHEMICAL ENGINEERING",
  "CHEMISTRY",
  "CIVIL ENGINEERING",
  "CLASSICS",
  "COGNITIVE SCIENCE",
  "COMMUNICATION STUDIES",
  "COMPARATIVE LITERARY STUDIES",
  "COMPUTER ENGINEERING",
  "COMPUTER SCIENCE BS",
  "COMPUTER SCIENCE BA",
  "DANCE",
  "EARTH AND PLANETARY SCIENCES",
  "ECONOMICS",
  "ELECTRICAL ENGINEERING",
  "ENGLISH: CREATIVE WRITING",
  "ENGLISH: LITERATURE",
  "ENVIRONMENTAL ENGINEERING",
  "ENVIRONMENTAL SCIENCES",
  "FRENCH",
  "GENDER AND SEXUALITY STUDIES",
  "GEOGRAPHY",
  "GERMAN",
  "GLOBAL HEALTH STUDIES",
  "HISTORY",
  "HUMAN COMMUNICATION SCIENCES",
  "HUMAN DEVELOPMENT IN CONTEXT",
  "INDUSTRIAL ENGINEERING",
  "INTEGRATED ENGINEERING STUDIES (MIES)",
  "INTEGRATED SCIENCE PROGRAM",
  "INTERNATIONAL STUDIES",
  "ITALIAN",
  "JEWISH STUDIES",
  "JOURNALISM",
  "LATINA AND LATINO STUDIES",
  "LEARNING AND ORGANIZATIONAL CHANGE",
  "LEARNING SCIENCES",
  "LEGAL STUDIES",
  "LINGUISTICS",
  "MANUFACTURING & DESIGN ENGINEERING (BS-MADE)",
  "MATERIALS SCIENCE AND ENGINEERING (BA)",
  "MATERIALS SCIENCE AND ENGINEERING (BS)",
  "MATHEMATICAL METHODS IN THE SOCIAL SCIENCES",
  "MATHEMATICS",
  "MECHANICAL ENGINEERING",
  "MIDDLE EAST NORTH AFRICAN STUDIES",
  "MUSIC PERFORMANCE: BRASS",
  "MUSIC PERFORMANCE: JAZZ STUDIES",
  "MUSIC PERFORMANCE: PERCUSSION",
  "MUSIC PERFORMANCE: PIANO",
  "MUSIC PERFORMANCE: STRINGS",
  "MUSIC PERFORMANCE: VOICE & OPERA",
  "MUSIC PERFORMANCE: WOODWINDS",
  "MUSIC STUDIES: COMPOSITION AND MUSIC TECHNOLOGY",
  "MUSIC STUDIES: MUSIC EDUCATION",
  "MUSIC STUDIES: MUSICOLOGY",
  "MUSIC STUDIES: MUSIC THEORY AND COGNITION",
  "NEUROSCIENCE",
  "PERFORMANCE STUDIES",
  "PHILOSOPHY",
  "PHYSICS AND ASTRONOMY",
  "POLITICAL SCIENCE",
  "PSYCHOLOGY",
  "RADIO/TELEVISION/FILM",
  "RELIGIOUS STUDIES",
  "RUSSIAN AND EAST EUROPEAN STUDIES",
  "RUSSIAN LANGUAGE, LITERATURE, AND CULTURE",
  "SCIENCE IN HUMAN CULTURE",
  "SECONDARY TEACHING",
  "SOCIAL POLICY",
  "SOCIAL SCIENCES (PART-TIME)",
  "SOCIOLOGY",
  "SPANISH",
  "STATISTICS",
  "THEATRE",
];
