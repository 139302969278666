export const minors = [
  "AFRICAN AMERICAN STUDIES",
  "AFRICAN STUDIES",
  "AMERICAN STUDIES",
  "ANTHROPOLOGY",
  "APPLIED MATHEMATICS",
  "ARCHITECTURAL ENGINEERING AND DESIGN",
  "ART HISTORY",
  "ARTS ADMINISTRATION",
  "ART THEORY AND PRACTICE",
  "ASIAN AMERICAN STUDIES",
  "ASIAN HUMANITIES",
  "ASIAN LANGUAGES AND CULTURES",
  "BIOTECHNOLOGY AND BIOCHEMICAL ENGINEERING",
  "BUSINESS GERMAN",
  "BUSINESS INSTITUTIONS",
  "CATHOLIC STUDIES",
  "CENTRAL SOUTHEASTERN EUROPEAN STUDIES",
  "CHEMISTRY",
  "CLASSICS",
  "COGNITIVE SCIENCE",
  "COMBINED ENGINEERING AND COMMUNICATION PROGRAM",
  "COMBINED MUSIC & ENGINEERING PROGRAM",
  "COMMERCIAL MUSIC",
  "COMPUTER SCIENCE BA",
  "CRITICAL THEORY",
  "DANCE",
  "DATA SCIENCE",
  "EARTH AND PLANETARY SCIENCES",
  "ECONOMICS",
  "ENGLISH: CREATIVE WRITING (CROSS-GENRE)",
  "ENGLISH: CREATIVE WRITING (SEQUENCE-BASED)",
  "ENGLISH: LITERATURE",
  "ENTREPRENEURSHIP",
  "ENVIRONMENTAL ENGINEERING",
  "ENVIRONMENTAL POLICY AND CULTURE",
  "FILM AND MEDIA STUDIES",
  "FRENCH",
  "GENDER AND SEXUALITY STUDIES",
  "GENERAL MUSIC",
  "GEOGRAPHY",
  "GERMAN",
  "GERMAN STUDIES",
  "GLOBAL HEALTH STUDIES",
  "GREEK",
  "HEBREW STUDIES",
  "HISTORY",
  "HUMAN COMMUNICATION SCIENCES",
  "HUMANITIES",
  "HUMANITIES (PART-TIME)",
  "INTERNATIONAL STUDIES",
  "ITALIAN",
  "JEWISH STUDIES",
  "LATIN",
  "LATINA AND LATINO STUDIES",
  "LATIN AMERICAN AND CARIBBEAN STUDIES",
  "LEGAL STUDIES",
  "LINGUISTICS",
  "MATERIALS SCIENCE AND ENGINEERING (BA)",
  "MATHEMATICS",
  "MIDDLE EAST NORTH AFRICAN STUDIES",
  "MUSIC COGNITION",
  "MUSIC COMPOSITION",
  "MUSIC CRITICISM",
  "MUSIC EDUCATION",
  "MUSICOLOGY",
  "MUSIC TECHNOLOGY",
  "MUSIC THEORY",
  "NATIVE AMERICAN AND INDIGENOUS STUDIES",
  "NORTHWESTERN UNDERGRADUATE PREMEDICAL SCHOLARS PROGRAM",
  "PHILOSOPHY",
  "PHYSICS AND ASTRONOMY",
  "POLITICAL SCIENCE",
  "PORTUGUESE LANGUAGE AND LUSOPHONE CULTURES",
  "PSYCHOLOGY",
  "RELIGIOUS STUDIES",
  "RUSSIAN AND EAST EUROPEAN STUDIES",
  "SCIENCE IN HUMAN CULTURE",
  "SOCIOLOGY",
  "SOUND DESIGN",
  "SPANISH",
  "STATISTICS",
  "THEATRE",
  "TRANSPORTATION AND LOGISTICS",
  "WORLD LITERATURE",
];
